@import '~antd/dist/antd.less';
// @import './styles/common.less';

html,body{
  min-width: 1200px;
}

.App {
  text-align: center;
}


