// 头部banner
.obanner_box{
    width: 100%;
    height: 500px;
    background-size: 100%;
    // background: url(https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/aboutUs_banner_adb77b73-913f-4a1a-a33f-0d9c44c7faff_1920x500.jpg?v=1594199288) top center no-repeat;
    background: url(https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/aboutusBanner.jpg) center center no-repeat;
    margin-top: -1px;
    .max_box{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        text-align: left;
        &>div{
            line-height: 200%;
        }
        .title{
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 10px;
        }

    }
}

// About Us -start
.idn_box{
    background-color: #fff;
    .idnb_cont{
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
        &>div{
            font-size: 30px;
        }
        &>div:nth-of-type(2){
            margin-bottom: 50px;
        }
        .title{
            font-size: 50px;
        }
        &>p{
            font-size: 20px;
            padding: 10px 0;
            text-align: left;
            line-height: 2em;
        }
        &>.modeImg{
            width: 68.49vw;
            height: 25.156vw;
            background-size: 100%;
            margin:20px 0 -40px;
        }
        &>.PsImg{
            width: 64.792vw;
            height: 34.167vw;
            background-size: 100%!important;
            margin-bottom: 4.333vw;
        }
        &>.modeImgDiv{
            width: 68.49vw;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
        }
        &>.modeImgDiv .modeImgDivItem{
            width: 15%;
            margin: 2%;
        }
        
    }
}
// HISTORY
.hy_box{
    .hyMark_cont{
        // background: #fff url(https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/about_banner_new_1920x630.jpg?v=1594283078) top center no-repeat;
        background: #fff url(https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/331.jpeg) top center no-repeat;
        width: 100%;
        height: 32.292vw;
        background-size: 100%;
    }
    .hyb_cont{
        background-image: url(//cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/history_bac_1_1920x.png?v=1593793837);
        width: 100%;
        height: 41.042vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 4em;   
        .section-title{
            font-size: 2.604vw;
            font-family: DINCond-Black;
            font-weight: 400;
            color: #fff;
            text-align: center;
            line-height: 1em;
            padding-top: 5.625vw;
            margin-bottom: 1.563vw;
        }    
        .section-body{
            // width: 1248px;
            width: 65vw;
            margin: 3.385vw auto 0;
            box-sizing: border-box;
            display: flex;
            -webkit-box-flex: 0;
            flex: 0 1 auto;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            // 每张卡片盒子
            .section-item{
                width: calc(100% / 5);
                position: relative;
                cursor: pointer;
                align-content: center;
                padding-left: 0;
                padding-right: 0; 
                -webkit-box-flex: 1;    
                flex-basis: 0;
                max-width: 100%;
                // 卡片模块   
                .poster{
                    overflow: hidden;
                    .poster-avatar{
                        width: 20.833vw;
                        height: 312px;
                        position: relative;
                        left: -35%;
                        overflow: hidden;
                        img{
                            // top: -1.8vw;
                            // height: 20.833vw;
                            // width: 20.833vw;
                            top: 1.8vw;
                            height: 15.833vw;
                            width: 15.833vw;                            
                            position: relative;
                        }
                    }
                    .poster-title{
                        position: relative;
                        text-align: center;
                        left: 50%;
                        right: 50%;
                        transform: translateX(-50%);
                        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
                        font-size: 1.719vw;
                        line-height: 1.719vw;
                    }
                }  
                // 弹出模块 
                .pop{
                    z-index: 30;
                    display: none;
                    position: absolute;
                    width: 37.5vw;
                    // height: 16.458vw;
                    height: 312px;
                    top: 0;
                    left: -100%;
                    .row{
                        width: 100%;
                        box-sizing: border-box;
                        display: flex;
                        -webkit-box-flex: 0;
                        flex: 0 1 auto;
                        -webkit-box-orient: horizontal;
                        -webkit-box-direction: normal;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .pop-avatar{
                            // height: 16.263vw;
                            height: 312px;
                            flex: none;
                            z-index: 30;
                            align-content: center;
                            padding-left: 0;
                            padding-right: 0;
                            max-width: 100%;
                            -webkit-box-flex: 1;
                            img{
                                top: -1.8vw;
                                // height: 20.833vw;
                                // width: 20.833vw;
                                width: 100%;
                                height: 100%;
                                position: relative;
                            }
                        }
                        .pop-summary{
                            align-items: center;
                            display: flex;
                            line-height: 1em;
                            font-size: 1vw;
                            color: #000;
                            background: #f7bc16;
                            left: 10.5vw;
                            width: 30vw;
                            // height: 16.263vw;
                            height: 312px;
                            position: absolute;
                            padding: 1.2em;
                            padding-left: 20%;
                            text-align: right;
                            justify-content: center;
                            font-family: Montserrat,auto;
                            align-content: center;
                            flex-basis: 0;
                            max-width: 100%;
                            -webkit-box-flex: 1;
                            flex: 0 0 auto;
                            .anima{
                               p{
                                font-style: normal!important;
                                font-family: montserrat_n4,auto;
                                font-weight: 100;
                               } 
                            }
                        }
                    }
                    &.vleft{
                        right: 0;
                        left: auto;
                    }
                }  
                 
            }
            .section-item:hover{
                .pop{
                    display: block;
                    .pop-avatar img{
                        animation:getBig 0.6s;
                    }
                    @keyframes getBig{
                        from {transform: scale(0.8);}
                        to {transform: scale(1);}
                    }
                    .pop-summary .anima{
                        animation:leftMove 0.6s;
                    }
                    @keyframes leftMove{
                        from {transform: translateX(-100%);}
                        to {transform: translateX(0%)}
                    }                    
                }
            }
        } 
    }
}
// About Us -end

// OurDesigners -start
.od_box{
    height: 500px;
    background-size: cover;
    background: url(https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/Banner_2x_0033281a-3b09-464b-a90f-df5edcc9238d_2000x.png?v=1596097309) top center no-repeat;
}
// Designers
.deg_box{
    background-color: #fff;
    padding: 80px 0;
    *{
        box-sizing: border-box;
    }
    .degb_cont{
        width: 1152px;
        margin: 0 auto;
        padding: 8px 0;
        display: flex;
        *{
            box-sizing: border-box;
        }
        .author-image{
            width: 20%;
            height: 100%;
            border-left: 2px solid #ffc501;
            min-width: 256px;
            padding-right: 10px;
            padding-left: 15px;
            img{
                width: 229px;
                height: 229px;
                object-fit: contain;
            }
        }
        // 内容
        .brand-info-block{
            width: 80%;
            background: #f6f6f6;
            padding: 16px;
            height: 229px;
            .brand-image{
                width: 25%;
                float: left;
                text-align: center;
                vertical-align: middle;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                img{
                    max-height:10vw;
                    vertical-align: middle;
                }
            }
        }
        .brand_box{
            .designer-li-left{
                float: right;
                text-align: left;
                width: 75%;
                display: block;
                padding-right: 50px;
                font-size: 24px;
                .brand-name{
                    float: left;
                    padding-right: 10px;
                    font-weight: 800;
                }
            }
            .brand-desc{
                height: 100px;
                overflow-y: scroll;
                font-size: 13px;
                p{
                    width: 90%;

                }
            }
            .brand-url{
                margin-top: .5em;
                div{
                    background: #000;
                    border-radius: 50px;
                    cursor: pointer;
                    height: 36px;
                    display: inline-block;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 10px 20px;
                    span{
                        color: #ffc501;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

// InvestorRelations
.ir_box{
    height: 640px;
    background-size: cover;
    background: url(https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/bannar_investor_2000x.png?v=1608361320) top center no-repeat;
}

// INVESTOR RELATIONS
.irs_box{
    background-color: #fff;
    .irsb_cont{
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
        &>*{
            box-sizing: border-box;
        }
        .title{
            font-size: 50px;
            padding-bottom: 64px;
        }
        .cparco_box{
            display: flex;
            flex-wrap: wrap;
            .cparco_item{
                display: flex;
                background-color: #C2C2C2;
                color: white;
                text-align: center;
                width: 30%;
                padding: 2% 5%;
                margin: 0px 1% 30px;
                align-items: center;
                justify-content: center;
                line-height: 1.2;
                cursor: pointer;
                min-height: 6rem;
                font-size: 1.5rem;
            }
            .cparco_item:hover,.current{
                background-color: #FFC501;
                color: #000;
            }
            
        }
    }
}
// cparco_box
.cparcos_box{
    background-color: #F6F6F6;
    padding-bottom: 64px;
    .irsb_cont{
        .title{
            font-size: 2.2em;
            font-weight: bold;
            padding-bottom: 40px;
        }
        .list-item{
            padding: 20px 0;
            text-align: left;
            .li_title{
                font-weight: 600;
                padding-bottom: 1em;
                font-size: 22px;
            }
            .li_body{
                min-height: 7em;
                padding: 8px;
                display: flex;
                align-items: baseline;
                border-bottom: 1px solid #D3D3D3;
                font-size: 1.2rem;
                color: #333;
                .libody_PDF{
                    margin: 1em;
                    padding: 4px 8px;
                    background: #E4E4E4;
                    font-size: 12px;
                    text-transform: uppercase;
                    border-radius: 4px;
                }
                .libody_list{
                    .times{
                        color: gray;
                    }
                }
            }
        }
    }
}