// SMCont内容模块
.Cont_Home{
    background-color: #fff;
    //1 轮播图
    .SMBanner{
        img{
            width: 100%;
            height: 100%;
            object-fit:fill;
        }
    }

    //2 商品展示区
    .Cont_goodsCont{
        padding: 20px 0 ;
        //2.1 新品展示
        .NS_itemCont{
            width: 1200px;
            margin: 0 auto;
            padding: 36px 0;
            .section-header{
                margin-bottom: 36px;
                h2{
                    font-size: 38px;
                }
            }
            .section-item{
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                .si_imgCont{
                    width: 300px;
                    height: 390px;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .header{
                        width: 270px;
                        height: 270px;
                        position: relative;
                        .goodsImg{
                            width: 100%;
                            height: 100%;
                        }
                        .mask{
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: rgba(0, 0, 0, 0.35);
                            .BuyBtn{
                                cursor: pointer;
                                border-radius: 15px;
                                width: 150px;
                                height: 36px;
                                line-height: 36px;
                                padding: 1px 6px;
                                color: rgb(255, 206, 59);
                                background-color: #fff;
                                margin: 117px auto;
                                font-weight: bold;
                            }
                        }
                    }
                    .header:hover{
                        .mask{
                            display: block;
                        }
                    }
                    .title,.price{
                        font-size: 15px;
                        padding: 0 15px;
                    }
                    .title{
                        font-weight: bold;
                    }
                    .price{
                        font-size: 16px;
                    }
                }
                
            }
            .viewAll_box{
                margin-top: 20px;
                width: 100%;
                .item{
                    margin: auto;
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    color: #aaa;
                }
                .item:hover{
                    background-color: #ddd;
                    color: #000;
                    border-bottom: 2px solid rgb(255, 206, 59);
                }
            }
        }
        // HOT PRODUCTS
        // ARTIST COLLECTION
    }

    //3 关于我们-视频区
    .Cont_ABVideo{
        width: 100%;
        // background-image: url(https://cdn.shopifycdn.net/s/files/1/0339/7091/3412/files/about-popmart_2000x.png?v=1596014761);
        background-image: url(https://mumodel.oss-cn-shanghai.aliyuncs.com/0000yimo_official/aboutus/332.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        &>div{
            line-height: 40px;
        }
        .title{
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .desc{
            font-size: 16px;
            max-width: 1200px;
        }
    }

    //4 IP&Designer
    .Cont_ipDes{
        .idBox{
            width: 1200px;
            margin: 0 auto;
            padding: 36px 0;
            .ipsi_box{
                border-radius: 50%;
                box-sizing: border-box;
                width: 200px;
                height: 200px;
                padding: 12px; 
                .mask_ic{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                    position: relative;
                    transition:all .3s linear;
                    cursor: pointer;
                    border: 4px solid rgba(255, 255, 255, 0);
                    padding: 6px; 
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    .Classify{
                        position: absolute;
                        z-index: 99;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        color: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
                .mask_ic:hover{
                    border: 4px solid rgb(255, 206, 59);;
                    transform: scale(1.14);
                }
            }
        }
    }
}