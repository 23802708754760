@import '../styles/common.less';

// 导航栏样式
.header-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    .LeftCont{
        display: flex;
        align-items: center;
        &>div{
            margin: 0 25px;
            height: 74px;
            line-height: 74px;
            .routerLink{
                color: #eee;
            }
            .actived{
                color: @color-hover-border;
                font-weight: bold;
            }
        }
        &>div:hover{
            border-bottom: 2px solid @color-hover-border;
        }
        .header-body-logo{
            height: 44px;
            cursor: pointer;
            margin: 0 25px;
        }
    }
    .RightCont{
        display: flex;
        align-items: center;
        .rightIcon_bar{
            height: 32px;
            margin: 0 35px;
        }
    }
}
.hoverMsg{
    color: red;
    width: 100%;
    max-height: 200px;
    padding-bottom: 40px;
    overflow-y: scroll;
    position: absolute;
    top: 74px;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    z-index: 99;
    .hoverContBox{
        width: 1200px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        .item{
            font-size: 14px;
            color: #333;
            margin: 0 30px;
            cursor: pointer;
            height: 50px;
        }
        &>.item{
            // border-bottom: 1px solid @color-hover-border;
        }

    }
}


// *************
// 底部footer样式
.footerBox{
    margin: 0 auto;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    &>div{
        width: 600px;
        // border: 1px solid red;
    }
    // 联系我们
    .ConcatUs_box{
        .title{
            color: #FFC501;
            font-size: 18px;
            font-weight: bold;
        }
        .formContYM{
            margin: 32px 100px;
            text-align: left;
            .initSumb{
                color: #FFC501;
                border: 1px solid #444;
                background-color: #444;                
                width: 100%;
                font-weight: bold;
            }
            .initSumb:hover{
                border: 1px solid #444;
            }

        }
    }
    // 菜单
    .menu_box{
        display: flex;
        justify-content: space-between;
        &>div{
            width: 50%;
            text-align: center;
            &>div{
                margin-bottom: 20px;
                cursor: pointer;
            }
            .title{
                font-size: 18px;
                font-weight: bold;
            }
        }
        
    }
    
}
.tdCont{
    padding: 0 10% 24px;
    color: #eee;
    .cont{
        text-align: left;
        span{
            margin: 12px ;
        }
    }
}


