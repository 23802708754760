// 省略内容
.overflow-e1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overflow-e2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.overflow-e3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}