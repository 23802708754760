.Shop_Box{
    background: #fff;
    // 头部标题
    .Shop_titleBox{
        padding: 50px 0 30px;
        background: #f5f5f5;
        color: #b5b5b5;
        .title{
            margin: 0 auto;
            width: 1200px;
            text-align: left;
            letter-spacing: 2px;
            font-weight: bold;
        }
        .actived{
            color: #FFC501;
        }
    }

    .clo_goodsDetailImg{
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        .clg_title{
            color: #ffc501;
            font-size: 20px;
            font-weight: bold;
            padding: 0 40px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        .clg_img{
            display: flex;
            flex-direction: column;
            max-width: 800px;
            margin: 0 auto;
            padding: 8px 0 36px;
        }

    }

    // 商品列表
    .Shop_ListBox{
        width: 1200px;
        margin: 0 auto;
        padding: 36px 0;
        background: #fff;
        display: flex;
        justify-content: space-between;
        .classify_box{
            width: 300px;
            max-width: 300px;
            overflow: hidden;
            text-align: left;
            &>div{
                padding: 10px 16px;
                cursor: pointer;
                span{
                    padding-bottom: 5px;
                    font-size: 14px;
                }
            }
            .actived{
                border-bottom: 2px solid #FFC501;
            }
        }
        .goods_box{
            width: 900px;
            margin-bottom: 40px;
            .gb_title{
                font-weight: bold;
            }
            .gb_box{
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;                
                .si_imgCont{
                    width: 300px;
                    height: 390px;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .header{
                        width: 270px;
                        height: 270px;
                        position: relative;
                        .goodsImg{
                            width: 100%;
                            height: 100%;
                        }
                        .mask{
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: rgba(0, 0, 0, 0.35);
                            .BuyBtn{
                                cursor: pointer;
                                border-radius: 15px;
                                width: 150px;
                                height: 36px;
                                line-height: 36px;
                                padding: 1px 6px;
                                color: rgb(255, 206, 59);
                                background-color: #fff;
                                margin: 117px auto;
                                font-weight: bold;
                            }
                        }
                    }
                    .header:hover{
                        .mask{
                            display: block;
                        }
                    }
                    .title,.price{
                        font-size: 15px;
                        padding: 0 15px;
                    }
                    .title{
                        font-weight: bold;
                    }
                    .price{
                        font-size: 16px;
                    }
                }              
            }
        }

        // 商品详情图片
        .Detail_box{
            width: 400px;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
            .PhotoLoupeCont{

            }
            .gdList{
                text-align: left;
                margin-top: 4px;
                width: 400px;
                max-width: 400px;
                height: 96px;
                overflow: scroll;
                &>div{
                    display: flex;
                    flex-wrap: nowrap;
                    img{
                        width: 96px;
                        height: 96px;
                        margin-left: 4px;
                    }
                }
                
            }
        }
        // 商品详情信息
        .Detail_msg{
            width: 800px;
            max-width: 800px;
            text-align: left;
            padding:20px 40px;
            font-size: 22px;
            &>*{
                margin-bottom: 18px;
            }
            .dm_name{
                font-weight: bold;
            }
            .dm_price{
                font-weight: normal;
                color: #222;
            }
            .det_Inf_title{
                font-size: 16px;
                margin-bottom: 6px;
            }
            .det_Inf_content{
                font-size: 14px;
                padding-left: 28px;
                margin-bottom: 54px;
                pre{
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                }
            }
            .initSumb{
                color: #FFC501;
                border: 1px solid #444;
                background-color: #444;                
                width: 240px;
                height: 50px;
                font-weight: bold;
                border-radius: 40px;
            }
            .initSumb:hover{
                border: 1px solid #444;
            }            
        }        
    }
}

